<template>
  <main class="error">
    <h2>La page recherchée n'existe pas.</h2>
  </main>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>